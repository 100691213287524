<template>
  <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
    <div>
      <div v-if="appData">
        <policy-alert :app-data="appData" />

        <policy-summary :app-data="appData" />

        <instructions :instructions-text="instructions" />

        <div class="form-group row mb-2 mt-10">
          <label class="col-2 col-form-label">Renewal Indexation</label>
          <div class="col-2 font-weight-bold">
            <ValidationProvider
              rules="required"
              name="appData.transaction.K_RenewalIndexation"
              v-slot="{ errors }"
            >
              <b-form-select
                v-model="appData.transaction.K_RenewalIndexation"
                :disabled="
                  isReadOnly || !!appData.documents.declarationForm.view_url
                "
              >
                <b-form-select-option
                  v-for="n in 11"
                  :value="n - 1"
                  :key="n"
                >
                  {{ n - 1 }}%
                </b-form-select-option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

        </div>

        <div class="form-group row mb-2 mt-2">
          <label class="col-2 col-form-label">Declaration Contact</label>
          <div class="col-4 font-weight-bold">
            <ValidationProvider
              rules="required"
              name="appData.transaction.uiDeclarationContact"
              v-slot="{ errors }"
            >
              <b-form-select
                v-model="appData.transaction.uiDeclarationContact"
                :disabled="isReadOnly || appData.statusFlags.declarationSent"
                @change="saveDeclarationContact"
              >
                <b-form-select-option
                  v-for="contact in sortedContacts"
                  :key="contact.id"
                  :value="contact"
                  >{{ contact.Name }} - {{ contact.Email }}
                </b-form-select-option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <Document
            :document-name="'Declaration Form'"
            :document-data="appData.documents.declarationForm"
            :get-client-declaration-email-template="getClientDeclarationEmailTemplate"
            :on-create-document="createDeclarationForm"
            :processing-indicator="creatingDeclarationForm"
            :is-read-only="isReadOnly"
            :is-submitted="appData.statusFlags.declarationReceived"
            :is-email-disabled="appData.statusFlags.declarationSent"
            :recreate-enabled="true"
        >
        </Document>

        <!-- Hidden input to validate if the user created the declaration form -->
        <ValidationProvider
          rules="required-action"
          name="appData.documents.declarationForm.id"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.documents.declarationForm.id" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <div v-if="appData.documents.declarationForm.view_url && !appData.statusFlags.declarationReceived">
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.statusFlags.declarationSent"
            v-slot="{ errors }"
          >
            <div class="row mt-6">
              <span
                class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
              >
                <label>
                  <input
                    type="checkbox"
                    :disabled="isReadOnly"
                    v-model="appData.statusFlags.declarationSent"
                    @change="
                      $emit('updateServerTransactionStatus', 'declarationSent')
                    "
                  />
                  <span></span>
                </label>
              </span>
              <label class="col-form-label font-size-h5 ml-2">
                I confirm that I have sent the Declaration Form to the Strata
                contact.
              </label>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import Document from "../../../common/Document";

export default {
  name: "DeclarationPage",

  components: {
    PolicySummary,
    PolicyAlert,
    Instructions,
    Document,
  },

  props: {
    appData: Object,
    isReadOnly: Boolean,
    getClientDeclarationEmailTemplate: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      creatingDeclarationForm: false,
    };
  },

  computed: {
    instructions: function () {
      return (
        "Adjust the Renewal Indexation percentage as appropriate and update the Declaration Contact if necessary, " +
        "then create the Declaration Form. The Indexation percentage will be applied to the Building Sum Insured, " +
        "Common Contents Cover and Loss of Rent Cover in the Declaration Form. You will be able to review the newly" +
        " created Declaration Form prior to sending it to the client."
      );
    },

    sortedContacts() {
      return [...this.appData.strataCompany.contacts].sort((a, b) =>
        a.Name.localeCompare(b.Name)
      );
    }
  },

  methods: {
    createDeclarationForm: function () {
      let renewalIndexation = this.appData.transaction.K_RenewalIndexation;

      this.creatingDeclarationForm = true;
      this.$renewalDataService
        .createDeclarationForm(renewalIndexation)
        .then(({ data }) => {
          if (data.result) {
            let decFormInfo = data.result;
            this.appData.transaction.K_DeclarationFormStatus =
              decFormInfo.K_DeclarationFormStatus;
            this.appData.transaction.K_DeclarationFormLink =
              decFormInfo.K_DeclarationFormLink;
            this.appData.documents.declarationForm = {
              id: decFormInfo.id,
              view_url: decFormInfo.K_DeclarationFormLink,
            };

            this.$emit("updateWizardAppDataString");
          }
          this.creatingDeclarationForm = false;
        })
        .catch((error) => {
          this.creatingDeclarationForm = false;
          this.$emit("handleError", error);
        });
    },

    saveDeclarationContact: function () {
      let decContact = this.appData.transaction.uiDeclarationContact;
      let txnData = {
        K_DeclarationContact: decContact.Name,
        Email: decContact.Email,
      };

      this.$renewalDataService
        .updateTransactionData(txnData)
        .then((/*{ data }*/) => {
          this.$emit("updateWizardAppDataString");
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },

};
</script>
